export default {
  mywork: {
    background: '#FFF1F0',
    dark2: '#B7243D',
    dark1: '#DB3443',
    base: '#ff4848',
    baseRgb: '255, 72, 72',
    light1: '#FF8175',
    light2: '#FFA491',
    lightBlue: '#E9F2F4',
    lightOrange: '#FFECEC',
  },
  border: {
    lightGray: '#F0F0F0',
    gray: '#808080',
    light: '#EEE',
    medium: '#D9D9D9',
    mediumRgb: '217, 217, 217',
    transparentBlack: '#00000020',
  },
  button: {
    accept: {
      default: '#52C41A',
      active: '#389E0D',
    },
    reject: {
      default: '#F5222D',
      active: '#CF1222',
    },
    secondary: {
      hover: '#FF7570',
      active: '#D93237',
    },
    background: {
      white: '#FFFFFF',
    },
    download: {
      enabled: '#FF4848',
      disabled: '#00000040',
    },
    share: {
      enabled: '#FF4848',
      disabled: '#00000040',
    },
  },
  container: {
    background: {
      hover: '#FBFBFB',
      light: '#FCFCFC',
      dashed: {
        light: '#f6f6f6',
        dark: '#fcfcfc',
      },
      grey: '#FAFAFA',
      error: {
        dark: '#DB3443',
        light: '#ff4848',
      },
    },
  },
  icon: {
    grey: '#CCC',
    lightGrey: '#DDD',
    lightGray2: '#00000040',
    green: '#73D13D',
    red: '#FF4D4F',
    darkGrey: '#BFBFBF',
    blue: '#006AA8',
    lime: '#5B8C00',
    calendulaGold: '#AD6800',
    lightCalendulaGold: '#FAAD14',
    polarGreen: '#52C41A',
    neutral_five: '#D9D9D9',
    sunsetOrange: '#FA8C16',
  },
  loader: {
    background: '#F3F3F3',
    foreground: '#ECEBEB',
  },
  text: {
    dark: '#666',
    grey: '#999',
    light: '#AAA',
    lightGray: '#555555',
    optional: '#00000073',
    darkBlack: '#000000cc',
    transparentBlack: '#000000de',
    transparentGray: '#00000099',
    disabled: {
      grey: '#CCC',
    },
    oilBlue: '#004060',
    warning: '#FF4D4F',
    red: '#FF4848',
    white: '#FFF',
    menu: {
      primary: '#000000BF',
      secondary: '#0000008C',
    },
  },
  status: {
    default: '#E9E9E9',
    success: '#6ba951',
    changed: '#999',
    warning: '#FFC183',
    error: '#DB3443',
    alert: '#FAAD14',
  },
  timetracking: {
    geofence: '#108DAA',
    outside: '#FF8711',
    inside: '#0DC73E',
    suggestion: '#FFC18335',
  },
  rowStatus: {
    modified: '#FFF2E9',
  },
  plan: {
    display: {
      background: '#FFF1F0',
    },
  },
  chat: {
    background: '#FAF9F8',
  },
  progressBar: {
    background: '#F5F5F5',
    success: {
      background: '#52C41A',
    },
    failed: {
      background: '#ffa940',
    },
    error: {
      background: '#f5222d',
    },
  },
  progressCircle: {
    red: '#F5222D',
    yellow: '#FADB14',
    green: '#52C41A',
  },
  alternatedColors: {
    light: '#FAFAFA',
    dark: '#F0F0F0',
  },
  billingAlert: {
    inDueDate: {
      background: '#E6F7FF',
      border: '#91D5FF',
      icon: '#1890FF',
    },
    overdue: {
      background: '#FFFBE6',
      border: '#FFE58F',
      icon: '#FAAD14',
    },
    expiring: {
      background: '#FFF7E6',
      border: '#FFD591',
      icon: '#FA8C16',
    },
    expired: {
      background: '#FFF1F0',
      border: '#FFCCC7',
      icon: '#FF4848',
    },
  },
  medicalCertificates: {
    statusTag: {
      created: undefined,
      machineVerifying: 'geekblue',
      machineExtracting: 'purple',
      readyToReview: 'gold',
      approved: 'green',
      rejected: 'red',
    },
  },
  uploadStatus: {
    waiting: '#BFBFBF',
    cancelled: '#595959',
    failed: '#FAAD14',
    uploading: '#BFBFBF',
    done: '#52C41A',
    error: '#F5222D',
  },
  uploadActionIcon: '#8C8C8C',
  scrollBar: {
    background: '#F1F1F1',
    thumb: '#CCCCCC',
    thumbHover: '#555555',
  },
  infoModalStatus: {
    warning: '#FAAD14',
    error: '#F5222D',
    success: '#52C41A',
    info: '#1890FF',
  },
};
